<template>
  <div class="container-xs">
    <AppBackHeader to="/search/summary">CHOOSE HOTEL</AppBackHeader>
    <div style="display: grid; gap: 1rem; margin-top: 3rem">
      <HotelCardDetails
        v-for="{ hotel, room, price, isActive } in hotelMap"
        :key="hotel.id"
        :hotel="hotel"
        :room="room"
        show-info
        show-card
        :info-price="price"
        :data-selected="isActive"
        @click="onSelect(hotel.id)"
      />
    </div>
    <div
      style="position: sticky; bottom: 2rem; margin-top: 2rem; padding: 0 1rem"
    >
      <XButton
        color="secondary"
        type="large"
        style="color: black; width: 100%; min-width: initial !important"
        @click="$router.push('/search/hotels/map')"
      >
        <IconMap />
        <p style="margin-left: 4px">SHOW MAP</p>
      </XButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppBackHeader from '../../../components-rf/AppBackHeader.vue'
import HotelCardDetails from '../../../components-rf/search/hotels/HotelCardDetails.vue'
import { scrollIntoView } from '../../../other/utils/scrollIntoView'
import XButton from '../../../components/common/global/XButton.vue'
import IconMap from '../../../components-rf/icons/common/IconMap.vue'

export default {
  name: 'TripHotels',
  components: { AppBackHeader, HotelCardDetails, XButton, IconMap },
  mounted() {
    scrollIntoView(`[data-selected='true']`)
  },
  computed: {
    ...mapGetters('SearchState', ['getActive', 'getSelected']),
    hotelMap() {
      const { hotel: activeHotel, selectableHotels } = this.getActive

      return selectableHotels.map(hotel => {
        const isActive = hotel.id === activeHotel.id
        const selection = isActive
          ? this.getActive
          : this.getSelected({ hotelId: hotel.id })
        return {
          isActive,
          room: selection.room,
          price: selection.hotelPrice,
          hotel,
        }
      })
    },
  },
  methods: {
    ...mapActions('SearchState', ['select']),
    getHotelRoom(hotel) {
      return this.getActive.hotel.id === hotel.id
        ? this.getActive.room
        : this.getSelected({ hotelId: hotel.id }).room
    },
    onSelect(hotelId) {
      this.select({ hotelId })
      this.$router.push(`/search/hotel/details`)
    },
  },
}
</script>

<style scoped lang="scss"></style>
